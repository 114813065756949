import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { FaStar, FaRegStar, FaStarHalfAlt } from 'react-icons/fa'

import is from 'is_js'

import loadable from '@loadable/component'
import { roundToNearest } from '../helpers/roundToNearest'

const PortableText = loadable(() => import('./PortableText'))
const SchemaReview = loadable(() => import('./Schema/Review'))
const SchemaAggregateRating = loadable(() => import('./Schema/AggregateRating'))

// todo: Schema markup
// todo: Pull reviews based on the reference id

const ReviewDisplay = ({
  textClasses,
  reviewId,
  byType,
  specific,
  showReviewContent,
  nameLink,
}) => {
  const language = process.env.GATSBY_SITE_LANGUAGE
  const [showReviews, setShowReviews] = useState(3)

  //! pulling the references as we'll need these for filtering the reviews on a per page basis
  const data = useStaticQuery(graphql`
    query {
      reviews: allSanityReview {
        nodes {
          _id
          author
          _rawBody
          rating
          references {
            ... on SanityRoom {
              id
              _id
              _type
              slug {
                current
              }
            }
            ... on SanityRetreat {
              id
              _id
              _type
              slug {
                current
              }
            }
            ... on SanityPerson {
              id
              _id
              _type
            }
            ... on SanityCoaching {
              id
              _id
              _type
            }
          }
        }
      }
    }
  `)

  let reviews = data.reviews.nodes

  // filter by specific reviews
  if (specific.length) {
    const specificArray = []

    specific.forEach(item => {
      specificArray.push(item._id)
    })

    // ! having to filter the main gql as sanity doesnt give us _rawBody for the PortableText
    const reviewsFiltered = reviews.filter(review =>
      specificArray.includes(review._id)
    )

    // override the reviews with the filtered reviews
    if (reviewsFiltered) {
      reviews = reviewsFiltered
    }
  }

  // filter the reviews to see if the page id is inside
  if (reviewId && byType === 'Individual') {
    const reviewsFiltered = reviews.filter(review => {
      const idFound = review.references.filter(
        reference => reference._id === reviewId
      )

      return idFound.length !== 0
    })

    // override the reviews with the filtered reviews
    if (reviewsFiltered) {
      reviews = reviewsFiltered
    }
  }

  // filter the reviews for the byType
  if (typeof byType !== 'undefined' && byType !== 'All reviews') {
    const reviewsFiltered = reviews.filter(review => {
      let check = ''
      switch (byType) {
        case 'People':
          check = 'SanityPerson'
          break
        case 'Retreats':
          check = 'SanityRetreat'
          break
        case 'Coaching':
          check = 'SanityCoaching'
          break
        default:
        case 'Rooms':
          check = 'SanityRoom'
          break
        case 'Current Page':
          check = 'CurrentPage'
          break
      }

      let idFound = false

      if (check === 'CurrentPage') {
        idFound = review.references.filter(
          reference => reference._id === reviewId
        )
      } else {
        idFound = review.references.filter(
          reference => reference.__typename === check
        )
      }

      return idFound.length !== 0
    })

    // override the reviews with the filtered reviews
    if (reviewsFiltered) {
      reviews = reviewsFiltered
    }
  }

  const reviewTotal = reviews.length

  let reviewSum = 0
  let reviewAverage = 0
  let reviewAverageRound = 0
  let reviewMax = 0

  // get all the rating values into an array
  reviews.forEach(review => {
    if (review.rating > reviewMax) {
      reviewMax = review.rating
    }

    reviewSum += review.rating
  })

  // grab the averages and round to the nearest 0.5
  if (reviewSum > 0) {
    reviewAverage = reviewSum / reviewTotal
    reviewAverageRound = roundToNearest(reviewAverage, 0.5)
  }

  if (reviewSum === 0) {
    return null
  }

  const reviewText = `${reviewTotal} review${reviewTotal === 1 ? '' : 's'}`

  return (
    <div>
      <div className="flex items-center">
        <Stars count={reviewAverageRound} />
        <p className={`${textClasses || 'text-14px '}`}>
          {nameLink && <a href="#reviews">{reviewText}</a>}
          {!nameLink && <span>{reviewText}</span>}
        </p>
        <SchemaAggregateRating
          total={reviewTotal}
          max={reviewMax}
          average={reviewAverage}
        />
      </div>
      {showReviewContent && (
        <>
          <div className="relative grid grid-cols-12 py-8 mx-auto text-left lg:grid-cols-9 lg:gap-32">
            {reviews.map((review, index) => {
              let content = false
              // show a max of 3
              if (index < showReviews) {
                content = (
                  <div key={index} className="col-span-12 mt-8 lg:col-span-3">
                    <Stars count={review.rating} index={index} />
                    <div className="mt-4">
                      <PortableText blocks={review._rawBody} isReviews />
                      <div className="mt-4 prose">
                        <p>- {review.author}</p>
                      </div>
                    </div>
                    <SchemaReview
                      rating={review.rating}
                      author={review.author}
                      content={review._rawBody[0].children[0].text}
                    />
                  </div>
                )
              }

              return content
            })}
          </div>
          <button
            type="button"
            onClick={() => setShowReviews(showReviews + 6)}
            className={`${
              showReviews > reviewTotal ? 'hidden' : ''
            } relative inline-flex rounded-4 border-blue-500 text-blue-500 border-2 transition duration-300 hover:bg-blue-100 leading-none text-16 px-4 py-3`}
          >
            {language == 'en' ? 'View all guest reviews' : 'Visualizza tutte le recensioni'}
          </button>
        </>
      )}
    </div>
  )
}

/**
 *  Generates the stars for output
 *
 * @param {count} number the star rating
 */
const Stars = ({ count }) => {
  let starFull = 0
  let starHalf = false
  let starEmpty = 0

  // if we're  decimal we'll need a hald star
  if (is.decimal(count)) {
    starHalf = true
    starFull = Math.floor(count)
    starEmpty = 5 - (starFull + 1)
  } else {
    starFull = count
    starEmpty = 5 - starFull
  }

  // build a string of star icons
  const stars = []
  const starStyles = 'w-20px h-20px'
  let i
  if (starFull > 0) {
    for (i = 1; i <= starFull; i += 1) {
      stars.push(<FaStar key={`full-${i}`} className={starStyles} />)
    }
  }

  if (starHalf) {
    stars.push(<FaStarHalfAlt key="half" className={starStyles} />)
  }

  if (starEmpty > 0) {
    for (i = 1; i <= starEmpty; i += 1) {
      stars.push(<FaRegStar key={`empty-${i}`} className={starStyles} />)
    }
  }

  return (
    <div className="flex mr-2 text-yellow-500 transform -translate-y-1px">
      {stars}
    </div>
  )
}

ReviewDisplay.propTypes = {
  textClasses: PropTypes.string,
  reviewId: PropTypes.string,
  byType: PropTypes.string,
  specific: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  showReviewContent: PropTypes.bool,
  nameLink: PropTypes.bool,
}

ReviewDisplay.defaultProps = {
  textClasses: ``,
  reviewId: ``,
  byType: ``,
  specific: {},
  showReviewContent: false,
  nameLink: false,
}

Stars.propTypes = {
  count: PropTypes.number,
}

Stars.defaultProps = {
  count: ``,
}

export default ReviewDisplay
