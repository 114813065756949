/**
 * Rounds the number provided in value the nearest step
 *
 * e.g  roundToNearest(10.43, 0.5) would return 10.5
 *
 * @param {number} value the number to be rounded
 * @param {number} step the step to round the number to
 */
export function roundToNearest(value, step = 1) {
  const inv = 1.0 / step
  return Math.round(value * inv) / inv
}
